import React from 'react';
import {Helmet} from 'react-helmet';

import {useScript} from 'hooks';
import {browser} from 'utils';
import CryptoJS from 'crypto-js';

export const TagsHelmet = () => (
    <Helmet>
        <script>
            {`
                tc_vars = []
            `}
        </script>
        <script
            src="https://cdn.tagcommander.com/2406/tc_AXAUsineasites_1.js"
            async
        />
    </Helmet>
);

const APPLICATIONS = {
    collective: 'easyprojets',
    individual: 'ma_retraite_360',
    individual_transmission: 'ma_transmission_360'
};

const SALT = 'q3ypax';
const getMainCompany = (user) => {
    if (!user || !user?.scopes) return null;

    const scopes = user?.scopes;
    if (scopes && !['ais', 's2e', 'axa'].some((element) => scopes?.includes(element))) {
        return null;
    }

    if (user?.origin !== 'collective') return user.axa_profile?.company_name || null;
    if (user?.origin === 'collective') {
        if (scopes.includes('s2e') && user.s2e_profile?.companies?.length > 0) {
            return user.s2e_profile.companies?.find((company) => company.main_company)?.name;
        } else if (scopes.includes('ais') && user.ais_profile?.ais_account_details?.length > 0) {
            return user.ais_profile?.ais_account_details?.find((account) => account.status === 'ENCOURS')?.company_name;
        }
    }
    return null;
};

const sanitizePage = (page, pageUrl, application) => {
    if (page) return `${application}::${page}`;
    if (pageUrl) return `${application}${pageUrl}`;
};

const getPageURL = () => {
    let pageUrl = window.location.href.split(window.location.origin)[1].split('?')[0].replaceAll(/[#/]/g, '::');
    pageUrl = pageUrl.replaceAll(/\d/g, 'X');
    return pageUrl;
};

const sanitizePageURl = (isCta, page) => {
    const pageUrl = getPageURL();
    if (pageUrl === '::') {
        return `${page.replaceAll('::', '/')}`;
    }
    return isCta ? window.tc_vars?.page_url : `${pageUrl}::${page}`.replaceAll('::', '/');
};

export const sendTag = (user, page, optionalsDatalayer, isCta) => {
    if (window.location.pathname === '/login') return;
    const application = APPLICATIONS[user.origin] || 'easyprojets';
    page = page ? page?.toLowerCase()?.replaceAll(' ', '_') : '';

    const mainCompany = getMainCompany(user);
    const company = mainCompany ? {entreprise: mainCompany} : {};
    const axa_advisor = user.axa_advisor?.type ? {reseau_distribution: user.axa_advisor?.type || null} : {};
    const pageUrl = getPageURL();
    const xitiXtpage = isCta ? window.tc_vars?.xiti_xtpage : sanitizePage(page, pageUrl, application);
    const idClient = {};
    if (user.origin === 'collective') {
        idClient.ID_client = CryptoJS.SHA256(`${user.oauth_profile?.sub}${SALT}`).toString();
    } else if (user.origin === 'individual' || user.origin === 'individual_transmission') {
        idClient.ID_client = CryptoJS.SHA256(`${user.axa_profile?.customer_id}${SALT}`).toString();
    }

    try {
        const options = {
            detail: {
                Datalayer: {
                    origine: user.origin || 'public',
                    environnement: process.env.REACT_APP_ENVIRONMENT,
                    nom_site: 'easyprojets',
                    xiti_xtpage: xitiXtpage,
                    page_url: sanitizePageURl(isCta, page),
                    ...idClient,
                    ...company,
                    ...axa_advisor,
                    ...optionalsDatalayer
                },
                Event: !isCta ? 'N' : 'C',
                Type: !isCta ? 'V' : 'A',
                Label: !isCta ? 'xiti_xtpage' : page
            }
        };

        let tagEvent;
        if (browser.isIE11()) {
            let event = document.createEvent('CustomEvent');
            tagEvent = event.initCustomEvent('sendTag', false, false, options);
        } else {
            tagEvent = new CustomEvent('sendTag', options);
        }

        if (tagEvent) window.dispatchEvent(tagEvent);
    } catch (error) {
        console.error(error);
    }
};

const TagsProvider = ({children}) => {
    useScript(
        process.env.REACT_APP_ENVIRONMENT === 'production'
            ? 'https://cdn.tagcommander.com/2406/tc_AXAUsineasites_5.js'
            : 'https://cdn.tagcommander.com/2406/uat/tc_AXAUsineasites_5.js'
    );

    return <>{children}</>;
};

export default TagsProvider;
